import React from "react"

import SEO from "components/seo"
import Link from 'components/link'

export default () => (
  <>
    <SEO title="404: Not found" />
    <div className={'page-404'}>
      <h1>
        This page couldn't be found.
    </h1>
      <p>
        Perhaps head to the <Link to={'/'}>home page</Link>?
      </p>
    </div>
  </>
)